import { useRef } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import SliderComponent from "~/components/ui/Slider"
import SmallProductCard from "~/components/ui/SmallProductCard"
import ToOffert from "~/components/globals/Cart/components/ToOffer"
import type { TCart } from "~/managers/CartManager/_data/serializer"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type BottomFullCartProps = PropsWithClassName<{
  toOffer: NonNullable<TCart>["toOffer"]
  productSlider: NonNullable<TCart>["productSlider"]
  giftPanel: NonNullable<TCart>["giftPanel"]
}>

function BottomFullCart({ className, toOffer, productSlider, giftPanel }: BottomFullCartProps) {
  const customSliderRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className={clsx(className, css.BottomFullCart)}>
      <ToOffert toOffer={toOffer} giftPanel={giftPanel} />

      {productSlider.productSliderItems && (
        <SliderComponent
          containerProps={{
            sprinklesCss: {
              paddingTop: 28,
            },
          }}
          arrowNavigationPosition="top"
          topContainerProps={{
            sprinklesCss: {
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: { mobile: "center", tablet: "left" },
              mB: 12,
            },
          }}
          title={{
            className: text({
              design: "riposte-14",
              letterSpacing: 0.2,
              transform: "uppercase",
              color: "blue-deep",
              weight: "medium",
            }),
            content: productSlider.productSliderTitle,
          }}
          customSliderRef={customSliderRef}
        >
          {productSlider.productSliderItems?.map((item, index) => (
            <SmallProductCard
              key={`product-card-${index}`}
              product={item}
              sizesFromBreakpoint={[{ ratio: 1 }, { breakpoint: "md", ratio: 5 / 12 }]}
              isFromCartCrossSell
            />
          ))}
        </SliderComponent>
      )}
    </div>
  )
}

export default BottomFullCart
