"use client"

import { useCallback, useRef, useState, type ComponentProps } from "react"
import { usePathname } from "next/navigation"
import clsx from "clsx"

import { type GID, type UnlikelySelectedOption } from "@unlikelystudio/commerce-connector"

import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAddToCart } from "~/lib/shopify/hooks/useAddToCart"
import useGetCart from "~/hooks/cart/useGetCart"
import RoundedCta from "~/components/ui/RoundedCta"
import { getCrossSellProductLineItem } from "~/components/globals/Cart/components/CrossSell/_data/serializeCrossSellProductCard"
import CrossSellProductCardCheckbox from "~/components/globals/Cart/components/CrossSell/CrossSellProductCardCheckbox"
import CrossSellProductCardCheckboxPlaceholder from "~/components/globals/Cart/components/CrossSell/CrossSellProductCardCheckbox/Placeholder"
import { useCrossSellProductCardsQuery } from "~/components/globals/Cart/components/CrossSell/hooks/useCrossSellProductCardsQuery"
import { ToasterHead } from "~/components/globals/Cart/components/Toaster"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Panel, usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

type CartCrossSell = {
  onClick?: () => void
  onAddToCart?: () => void
  onClose?: () => void
  crossSellProductIds: GID[]
  selectedOptions: UnlikelySelectedOption[]
}

function CrossSell({ crossSellProductIds, selectedOptions, onClick, onAddToCart, onClose }: CartCrossSell) {
  const t = useTranslate()
  const pathname = usePathname()
  const { cart } = useGetCart()
  const { mutate: addCartLines, isLoading: addToCartLoading } = useAddToCart({
    onSuccessAddLines: () => {
      onAddToCart?.()
    },
  })
  const panelRef = useRef(null)
  const { removeCurrent } = usePanel()

  const { data, isLoading } = useCrossSellProductCardsQuery(crossSellProductIds)

  const [selectedItems, setSelectedItems] = useState<
    Parameters<NonNullable<ComponentProps<typeof CrossSellProductCardCheckbox>["onChange"]>>[0][]
  >([])

  const hasSelectedItems = selectedItems?.some(Boolean)

  // Methods
  const handleOnRemove = () => {
    onClose?.()
    removeCurrent?.()
  }

  const handleOnClick = () => {
    onClick?.()
  }

  const handleOnCrossSellProductCardCheckboxChange = (
    variant: Parameters<NonNullable<ComponentProps<typeof CrossSellProductCardCheckbox>["onChange"]>>[0],
    index: number
  ) => {
    setSelectedItems((prev) => {
      const next = [...prev]
      next[index] = variant
      return next
    })
  }

  const handleAddToCart = useCallback(() => {
    const lineItems = selectedItems
      ?.map((selectedItem, index) => {
        const product = data?.[index]?.product
        return selectedItem && product
          ? getCrossSellProductLineItem({
              product,
              pathname,
              index: cart?.lines?.length ?? 0 + index + 1,
              additionalAttributes: [{ key: PRODUCT_CUSTOM_ATTRIBUTES.IS_FROM_POP_IN_CROSS_SELL, value: "true" }],
              additionalTrackingData: { from_pop_in_cross_sell: true },
              ...selectedItem,
            })
          : null
      })
      ?.filter(Boolean)

    addCartLines(lineItems)
  }, [selectedItems])

  return (
    <Panel zIndex={50} clickOutsideRef={panelRef} onRemove={handleOnRemove}>
      <div className={clsx(css.CrossSell)} ref={panelRef}>
        <ToasterHead className={css.head} onClose={handleOnRemove}>
          {t("product_added_to_cart")}
        </ToasterHead>
        <p className={css.subtitle}>{t("product_complete_your_order")}</p>
        <div className={css.container}>
          {isLoading
            ? crossSellProductIds?.map((id) => <CrossSellProductCardCheckboxPlaceholder key={id} />)
            : data?.map((item, index) => (
                <CrossSellProductCardCheckbox
                  key={item?.id}
                  item={item}
                  selectedOptions={selectedOptions}
                  onChange={(variant) => handleOnCrossSellProductCardCheckboxChange(variant, index)}
                />
              ))}
        </div>
        <RoundedCta
          width="full"
          size="large"
          theme="blue-deep"
          onClick={hasSelectedItems ? handleAddToCart : handleOnClick}
          isLoading={addToCartLoading}
        >
          {t(hasSelectedItems ? "product_add_to_cart" : "cart_view")}
        </RoundedCta>
      </div>
    </Panel>
  )
}

export default CrossSell
