import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"

import { text } from "~/styles/variants"

export type EmbroideryColorProps = PropsWithClassName<{
  color: string
}>

function EmbroideryColor({ className, color }: EmbroideryColorProps) {
  return (
    <ClientTranslate
      as="li"
      tKey="embroidery_thread"
      className={clsx(
        className,
        text({
          design: "riposte-10",
          color: "blue-light",
        })
      )}
    >
      {" : "}
      {color}
    </ClientTranslate>
  )
}

export default EmbroideryColor
