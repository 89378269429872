import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type EmbroideryProps = PropsWithClassName<{
  value: string
  count: 1 | 2
}>

function Embroidery({ className, value, count }: EmbroideryProps) {
  return (
    <li className={clsx(css.Embroidery, className)}>
      <ClientTranslate
        as="span"
        tKey="embroidery"
        className={text({
          design: "riposte-13",
          color: "blue-light-txt",
        })}
      >
        {" "}
        {count}
      </ClientTranslate>
      <span
        className={text({
          design: "riposte-13",
          color: "blue-deep",
        })}
      >
        {" : " + value}
      </span>
    </li>
  )
}

export default Embroidery
