"use client"

import React, { type ComponentProps } from "react"
import clsx from "clsx"

import { FormError } from "~/components/ui/Form"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type TextareaProps = {
  textareaClassName?: string
  errorBackgroundColor?: Extract<Sprinkles["bgColor"], "white" | "grey-light-bg">
} & ComponentProps<"textarea">

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, textareaClassName, errorBackgroundColor = "grey-light-bg", ...props }, ref) => {
    return (
      <div className={clsx(css.InputContainer, className)}>
        <textarea ref={ref} className={clsx(css.Textarea, textareaClassName)} {...props} />
        <FormError
          className={clsx(
            css.ErrorMessage,
            sprinkles({
              position: "absolute",
              bgColor: errorBackgroundColor,
              marginLeft: 16,
              pX: 8,
            })
          )}
        />
      </div>
    )
  }
)

export default Textarea
