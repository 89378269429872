"use client"

import { type ComponentProps } from "react"

import useBreakpoint from "~/hooks/useBreakpoint"
import { useOnRouteChange } from "~/hooks/useOnRouteChange"
import CrossSell from "~/components/globals/Cart/components/CrossSell"
import Default from "~/components/globals/Cart/components/Default"
import CartToaster from "~/components/globals/Cart/components/Toaster"
import { useCartState } from "~/components/globals/Cart/useCartState"
import { usePanel } from "~/managers/PanelManager"

type CartProps =
  | {
      crossSellProductIds?: never
      selectedOptions?: never
    }
  | {
      crossSellProductIds: ComponentProps<typeof CrossSell>["crossSellProductIds"]
      selectedOptions: ComponentProps<typeof CrossSell>["selectedOptions"]
    }

function Cart({ crossSellProductIds, selectedOptions }: CartProps) {
  const isMobile = useBreakpoint("md")
  const [currentState, setCurrentState] = useCartState()
  const { reset } = usePanel()

  useOnRouteChange(reset)

  switch (currentState) {
    case "cross-sell":
      return (
        <CrossSell
          crossSellProductIds={crossSellProductIds ?? []}
          selectedOptions={selectedOptions ?? []}
          onClick={() => setCurrentState("cart")}
          onClose={() => setCurrentState("cart")}
          onAddToCart={() => setCurrentState(isMobile ? "toaster" : "cart")}
        />
      )
    case "toaster":
      return <CartToaster onClick={() => setCurrentState("cart")} onClose={() => setCurrentState("cart")} />
    case "cart":
      return <Default />
  }
}

export { Cart }
