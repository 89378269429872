import * as css from "./styles.css"

function CrossSellProductCardCheckboxPlaceholder() {
  return (
    <div className={css.CrossSellProductCardCheckbox()}>
      <div className={css.placeholder} />
    </div>
  )
}

export default CrossSellProductCardCheckboxPlaceholder
