import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import { getProductMetafieldValue, getVariantMetafieldValue } from "~/lib/shopify/constants"
import { COLORS_SELECTOR_TYPES } from "~/lib/shopify/constants/types"
import { serializeVariants, type TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { parseShopifyReferencesFromMetatield } from "~/lib/shopify/utils/parse-references-from-metafields"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink, { type TLink } from "~/components/ui/Link/_data/serializer"
import { OPTION_COLOR } from "~/components/ui/Product/ProductHeader/_data/constants"
import { serializeEmbroidery } from "~/components/ui/Product/ProductHeader/_data/serialize-embroidery"
import {
  getUniqColorsWithDotImage,
  type TColorWithDotImage,
} from "~/components/ui/Product/ProductHeader/_data/utils/get-uniq-colors-with-dot-image"

export type TProductSelector = InferReturn<typeof serializeProductSelector>
export type TAssociatedProductsColorsWithDotImage = TColorWithDotImage & { link: Nullish<TLink> }

export async function serializeProductSelector(
  product: UnlikelyProduct,
  locale: string,
  associatedProducts?: Nullish<UnlikelyProduct[]>
) {
  const variants = await serializeProductVariants(product, locale)
  const productVariantsSelector = await serializeProductVariantsColorSelector(product, locale)

  const associatedProductsColorsWithDotImage = (
    await Promise.all(
      associatedProducts?.map(async (associatedProduct) => {
        const parsedLink = handleZodSafeParse(linkSchema, associatedProduct, {
          errorContext: `Associated Product Link: ${associatedProduct?.handle}`,
        })

        const allVariantsUnavailable = associatedProduct?.variants?.every(
          (variant) => variant.availableForSale === false
        )

        const firstVariant = associatedProduct?.variants?.find(
          (variant) =>
            (allVariantsUnavailable && getVariantMetafieldValue(variant.metafields, "COLOR_DOT_IMAGE")) ||
            (variant.availableForSale && getVariantMetafieldValue(variant.metafields, "COLOR_DOT_IMAGE"))
        )
        const variant = firstVariant && (await serializeVariants([firstVariant], locale))

        const colorWithDotImage = variant && getUniqColorsWithDotImage(variant, COLORS_SELECTOR_TYPES.PRODUCT)[0]

        const link = parsedLink ? serializeLink(parsedLink, locale, false) : null

        if (!colorWithDotImage) return null
        return {
          ...colorWithDotImage,
          link,
        } satisfies TAssociatedProductsColorsWithDotImage
      }) ?? []
    )
  ).filter(Boolean)

  return {
    variants,
    associatedProductsColorsWithDotImage:
      associatedProducts && associatedProducts.length > 0 ? associatedProductsColorsWithDotImage : null,
    ...productVariantsSelector,
    embroidery: await serializeEmbroidery(product, locale),
  }
}

export async function serializeProductVariants(product: UnlikelyProduct, locale: string) {
  const market = getMarket(locale)
  const variants = await serializeVariants(product.variants, locale)
  const hidden = getProductMetafieldValue(product.metafields, "HIDE_VARIANTS_IN_SIMPLE_PRODUCTS")

  const hiddenVariants = parseShopifyReferencesFromMetatield(hidden) ?? []

  return variants.filter(
    (variant) => variant.id && !hiddenVariants.includes(variant.id) && !variant.hiddenOnMarket.includes(market)
  )
}

export async function serializeProductVariantsColorSelector(
  product: UnlikelyProduct,
  locale: string,
  opts?: {
    variants?: TVariant[]
  }
) {
  const processedVariants = opts?.variants ?? (await serializeProductVariants(product, locale))

  const colorsWithDotImage = getUniqColorsWithDotImage(processedVariants, COLORS_SELECTOR_TYPES.PRODUCT)

  const selectedColor = getProductMetafieldValue(product.metafields, "SELECTED_COLOR")
  const foundedColor =
    product?.variants
      ?.find((variant) => variant?.id === selectedColor)
      ?.selectedOptions?.find((option) => option?.name === OPTION_COLOR)?.value ?? null

  const defaultColor = foundedColor ? foundedColor : colorsWithDotImage[0]?.value ?? null

  return {
    colorsWithDotImage,
    defaultColor,
  }
}
