import clsx from "clsx"
import { m } from "framer-motion"

import type { PropsWithClassName } from "~/@types/generics"
import { useSerializePrice } from "~/data/price/hooks"
import useGetCart from "~/hooks/cart/useGetCart"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import type { TCart } from "~/managers/CartManager/_data/serializer"
import { clamp } from "~/utils/clamp"

import * as css from "./styles.css"

export type FreeDeliveryGaugeProps = PropsWithClassName<{
  freeDeliveryGauge: NonNullable<TCart>["freeDeliveryGauge"]
}>

function FreeDeliveryGauge({ className, freeDeliveryGauge }: FreeDeliveryGaugeProps) {
  const t = useTranslate()
  const { totalPrice, currencyCode } = useGetCart()

  const priceForFreeDelivery = freeDeliveryGauge.freeDeliveryGaugeNumber
  const percentNumber = totalPrice && (100 * totalPrice) / priceForFreeDelivery
  const percent = percentNumber && clamp(percentNumber, 100)

  const isFreeDeliveryGauge = percent && percent === 100
  const priceRest = !isFreeDeliveryGauge && totalPrice ? priceForFreeDelivery - totalPrice : null

  const serializedPriceRest = useSerializePrice({
    amount: priceRest ?? freeDeliveryGauge.freeDeliveryGaugeNumber,
    currencyCode: currencyCode,
  })

  if (!freeDeliveryGauge.freeDeliveryGaugeDisplay) return null

  return (
    <div className={clsx(className, css.FreeDeliveryGauge)}>
      <div className={css.price}>
        {/* TODO: serializePrice - useLocale ?  */}
        {isFreeDeliveryGauge
          ? t("cart_free_delivery_gauge_actived")
          : t("cart_free_delivery_gauge_unactived", { price: serializedPriceRest })}
      </div>
      <div className={css.gaugeWrapper}>
        <m.div
          className={css.gauge}
          initial={{ width: 0 }}
          animate={{ width: `${percent}%` }}
          transition={{ duration: 2 }}
        />
      </div>
    </div>
  )
}

export default FreeDeliveryGauge
