"use client"

import { useEffect, useState, type ChangeEvent } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"

import type { UnlikelySelectedOption } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { ColorCustomAttribute } from "~/lib/shopify/constants"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import Checkbox from "~/components/ui/Checkbox"
import { Image } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import { type TCrossSellProductCard } from "~/components/globals/Cart/components/CrossSell/_data/serializeCrossSellProductCard"
import { useCrossSellProductCard } from "~/components/globals/Cart/components/CrossSell/CrossSellProductCardCheckbox/hooks/useCrossSellProductCard"
import Select from "~/components/globals/Cart/components/CrossSell/CrossSellProductCardCheckbox/Select"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

const selectTheme = {
  white: "grey",
  grey: "white",
} as const

type CrossSellProductCardCheckboxProps = {
  item: TCrossSellProductCard
  selectedOptions?: UnlikelySelectedOption[]
  selectorsOrder?: ("size" | "confort" | "color")[]
  customTitle?: Nullish<string>
  customImage?: Nullish<TImage>
  onChange?(element: { variant: TVariant; dotColor: ColorCustomAttribute } | null): void
} & RecipeVariants<typeof css.CrossSellProductCardCheckbox>

function CrossSellProductCardCheckbox({
  item,
  selectedOptions,
  theme = "white",
  size: sizeTheme = "medium",
  selectorsOrder = ["size", "color", "confort"],
  customTitle,
  customImage,
  onChange,
}: CrossSellProductCardCheckboxProps) {
  const t = useTranslate()
  const [checked, setChecked] = useState(false)
  const [
    { color, confort, size, variant, image, price, dotColor, optionsConfort, optionsSize },
    { setColor, setConfort, setSize },
  ] = useCrossSellProductCard({ item, selectedOptions })

  // On Change
  const handleColorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setColor(e.target.value)
  }
  const handleConfortChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setConfort(e.target.value)
  }
  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSize(e.target.value)
  }

  useEffect(() => {
    onChange?.(checked && variant ? { variant: variant, dotColor } : null)
  }, [checked, variant, dotColor])

  // on Check
  const handleCheckboxClick = () => {
    setChecked((prev) => !prev)
  }

  const selectors = {
    color:
      item.colorsWithDotImage?.length > 1 && color ? (
        <Select
          value={color}
          onChange={handleColorChange}
          dotColorImage={dotColor.image}
          theme={selectTheme[theme]}
          withChevronDown={item.colorsWithDotImage.length > 1}
          disabled={item.colorsWithDotImage.length <= 1}
        >
          {item.colorsWithDotImage?.map(({ value, availableForSale }) => (
            <option key={value} value={value} disabled={!availableForSale}>
              {value} {!availableForSale ? ` - ${t("product_bundle_variant_out_of_stock")}` : ""}
            </option>
          ))}
        </Select>
      ) : null,
    size: size ? (
      <Select
        value={size}
        onChange={handleSizeChange}
        theme={selectTheme[theme]}
        withChevronDown={optionsSize.length > 1}
        disabled={optionsSize.length <= 1}
      >
        {optionsSize?.map(({ value, availableForSale }) => (
          <option key={value} value={value} disabled={!availableForSale}>
            {value} {!availableForSale ? ` - ${t("product_bundle_variant_out_of_stock")}` : ""}
          </option>
        ))}
      </Select>
    ) : null,
    confort: confort ? (
      <Select
        value={confort}
        onChange={handleConfortChange}
        theme={selectTheme[theme]}
        withChevronDown={optionsConfort.length > 1}
        disabled={optionsConfort.length <= 1}
      >
        {optionsConfort?.map(({ value, availableForSale }) => (
          <option key={value} value={value} disabled={!availableForSale}>
            {value} {!availableForSale ? ` - ${t("product_bundle_variant_out_of_stock")}` : ""}
          </option>
        ))}
      </Select>
    ) : null,
  } satisfies Record<NonNullable<CrossSellProductCardCheckboxProps["selectorsOrder"]>[number], JSX.Element | null>

  const processedTitle = customTitle ?? item.title
  const processedImage = customImage ?? image

  return (
    <div className={css.CrossSellProductCardCheckbox({ theme, size: sizeTheme })}>
      <label
        className={css.label({ disabled: !variant?.availableForSale })}
        htmlFor={item.id ?? undefined}
        onClick={variant?.availableForSale ? handleCheckboxClick : undefined}
      >
        <Checkbox className={css.checkbox} name={item.id ?? undefined} checked={checked} />
      </label>
      <p className={css.price}>+{price}</p>
      {processedImage && (
        <Image
          className={css.image}
          asPlaceholder
          noBackground
          ratio={"1/1"}
          sizesFromBreakpoints={[{ ratio: 1 / 4 }, { breakpoint: "md", ratio: 1 / 3 }]}
          {...processedImage}
        />
      )}
      <div className={css.content}>
        <p className={css.title}>{processedTitle}</p>
        <div className={css.selectorContainer}>{selectorsOrder.map((selector) => selectors[selector])}</div>
      </div>
    </div>
  )
}

export default CrossSellProductCardCheckbox
