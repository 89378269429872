import type { ComponentProps } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import Icon from "~/components/abstracts/Icon"
import { DEFAULT_COLOR_SIZE } from "~/components/globals/Cart/components/OptionTemplate/ColorOption/constants"

import * as css from "./styles.css"

type Select = ComponentProps<"select"> & RecipeVariants<typeof css.select> & { dotColorImage?: Nullish<TImage> }

function Select({ className, children, dotColorImage, theme, withChevronDown = true, ...props }: Select) {
  return (
    <div className={clsx(className, css.selectContainer)}>
      {dotColorImage && (
        <div className={css.dotColorContainer}>
          <Image
            className={css.dotColorImage}
            {...dotColorImage}
            ratio="1/1"
            sizesFromBreakpoints={[{ ratio: `${DEFAULT_COLOR_SIZE}px` }]}
          />
        </div>
      )}
      <select className={css.select({ color: Boolean(dotColorImage), withChevronDown, theme })} {...props}>
        {children}
      </select>
      {withChevronDown && <Icon className={css.ChevronDownIcon} name="ChevronDown" theme="blue-med" width={16} />}
    </div>
  )
}

export default Select
