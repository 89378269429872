"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { Stack } from "~/components/abstracts/Stack"
import GiftPanel from "~/components/globals/Cart/components/GiftPanel"
import type { TCart } from "~/managers/CartManager/_data/serializer"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type ToOfferProps = PropsWithClassName<{
  toOffer: NonNullable<TCart>["toOffer"]
  giftPanel: NonNullable<TCart>["giftPanel"]
}>

function ToOffer({ className, toOffer, giftPanel }: ToOfferProps) {
  const { add } = usePanel()
  if (!toOffer.toOfferDisplay) return null

  const onClick = () => {
    add(<GiftPanel giftPanel={giftPanel} />)
  }
  return (
    <div className={clsx(className, css.ToOffer)}>
      <Stack direction={"column"} gap={8}>
        <div className={css.title}>{toOffer.toOfferTitle}</div>
        <div className={css.subtitle}>{toOffer.toOfferSubtitle}</div>
      </Stack>
      <button onClick={onClick} className={css.add}>
        {toOffer.toOfferButtonLabel}
      </button>
    </div>
  )
}

export default ToOffer
