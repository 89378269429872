"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { ColorCustomAttribute } from "~/lib/shopify/constants"
import { Image } from "~/components/ui/Image"
import {
  DEFAULT_COLOR_SIZE,
  SMALL_COLOR_SIZE,
} from "~/components/globals/Cart/components/OptionTemplate/ColorOption/constants"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type ColorOptionProps = PropsWithClassName<{
  option: ColorCustomAttribute
  theme?: "default" | "small"
}>

function ColorOption({ className, option, theme = "default" }: ColorOptionProps) {
  return option.value ? (
    <li className={clsx(className, css.ColorOption, css.ColorOptionVariant[theme])}>
      {option.image && (
        <Image
          className={css.image}
          {...option.image}
          ratio="1/1"
          sizesFromBreakpoints={[{ ratio: theme === "default" ? `${DEFAULT_COLOR_SIZE}px` : `${SMALL_COLOR_SIZE}px` }]}
        />
      )}

      <span
        className={clsx(
          css.label,
          text({
            design: "riposte-10",
          })
        )}
      >
        {option.value}
      </span>
    </li>
  ) : null
}

export default ColorOption
