import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"

export function getVariantFromOption(variants: Nullish<TVariant[]>, optionId: Nullish<GID>) {
  return (
    variants?.find((variant) => {
      return variant.id === optionId
    }) ?? null
  )
}
