import { type GID } from "@unlikelystudio/commerce-connector"

import type { DistributiveOmit } from "~/@types/generics"
import { getProductMetafieldProccessedKeys, getVariantMetafieldProccessedKeys } from "~/lib/shopify/constants"
import { VARIANT_FIRST } from "~/lib/shopify/constants/variant"
import { getSfProducts } from "~/lib/shopify/queries/get-sf-products"
import { fromGidsToQueryIds } from "~/lib/shopify/utils/id"
import { uniq } from "~/utils/uniq"

export async function getShopifyProductsByIds(
  ids: (string | GID)[],
  {
    metafieldKeys = [],
    metafieldVariantsKeys = [],
    ...params
  }: DistributiveOmit<Parameters<typeof getSfProducts>[0], "first" | "query">,
  options?: Parameters<typeof getSfProducts>[1]
) {
  return getSfProducts(
    {
      first: ids.length,
      query: fromGidsToQueryIds(ids),
      imagesFirst: 180,
      includePrices: true,
      includeSelectedOptions: true,
      metafieldKeys: uniq([
        ...metafieldKeys,
        ...getProductMetafieldProccessedKeys(
          "TAG",
          "SUBTITLE",
          "IS_BUNDLE",
          "SELECTED_COLOR",
          "CUSTOM_DEFAULT_IMG",
          "CUSTOM_DEFAULT_IMG_HOVER",
          "BUNDLE_HIDE_FROM_PRICE_LABEL",
          "ASSOCIATED_PRODUCTS",
          "CUSTOM_BUNDLES_MIXED_COLORS",
          "HIDE_VARIANTS_IN_SIMPLE_PRODUCTS"
        ),
      ]),
      metafieldVariantsKeys: uniq([
        ...metafieldVariantsKeys,
        ...getVariantMetafieldProccessedKeys("BUNDLE_APP", "COLOR_DOT_IMAGE", "HIDDEN_ON_MARKET", "COLOR_TAG"),
      ]),
      variantsFirst: VARIANT_FIRST,
      collectionsFirst: 3,
      ...params,
    },
    options
  )
}
