import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import RichText from "~/components/ui/RichText"
import type { TCart } from "~/managers/CartManager/_data/serializer"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type ReassuranceProps = PropsWithClassName<{
  reassurance: NonNullable<TCart>["reassurance"]
}>

function Reassurance({ className, reassurance }: ReassuranceProps) {
  return (
    <div className={clsx(className, css.Reassurance)}>
      <RichText
        className={text({
          design: "riposte-13",
          weight: "light",
          color: "blue-deep",
          textAlign: "center",
        })}
        render={reassurance.reassuranceText}
      />
    </div>
  )
}

export default Reassurance
