import { useQuery } from "@tanstack/react-query"

import type { GID } from "@unlikelystudio/commerce-connector"

import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getShopifyProductsByIds } from "~/lib/shopify/queries/get-sf-product-by-ids"
import { getIsBundleProduct } from "~/components/ui/Product/utils/get-is-bundle-product"
import { serializeCrossSellProductCard } from "~/components/globals/Cart/components/CrossSell/_data/serializeCrossSellProductCard"
import { sortByIds } from "~/utils/sort-by-ids"

export function useCrossSellProductCardsQuery(ids: GID[]) {
  const locale = useLocale()

  return useQuery({
    queryKey: ["useCrossSellProductCardsQuery", ids],
    queryFn: async () => getCrossSellProductCards(ids, locale),
    enabled: !!ids.length,
  })
}

export async function getCrossSellProductCards(ids: GID[], locale: string) {
  const shopifyProductByIds = await getShopifyProductsByIds(ids, {
    locale,
  })

  const shopifyProductByIdsSortedAndFiltered = sortByIds(ids, shopifyProductByIds.products)
    // Remove Bundle products from cross-sell
    ?.filter((p) => !getIsBundleProduct(p))

  return await Promise.all(
    shopifyProductByIdsSortedAndFiltered.map(async (product) => serializeCrossSellProductCard(product, locale))
  )
}
