import { useMemo } from "react"

import type { Nullish } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import serializePrice from "~/data/price/serializer"

type useSerializePriceParams = {
  currencyCode: Nullish<string>
  amount: Nullish<number>
  additionalOptions?: Intl.NumberFormatOptions
}

export function useSerializePrice({ currencyCode, amount, additionalOptions }: useSerializePriceParams) {
  const locale = useLocale()

  return useMemo(() => {
    return serializePrice({
      locale,
      amount,
      currencyCode,
      additionalOptions,
    })
  }, [currencyCode, amount, locale])
}
