import type { Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { UNIVERSAL_COLOR_OPTION_VALUE } from "~/components/ui/Product/ProductHeader/_data/constants"
import { getVariantColor } from "~/components/ui/Product/ProductHeader/_data/utils/get-uniq-colors-with-dot-image"

export function getVariantsFromCurrentColor(variants: TVariant[], currentColor: Nullish<string>, locale: string) {
  const market = getMarket(locale)
  // Filter variants with same currentColor value
  return variants?.filter((variant) => {
    return (
      ((!Boolean(currentColor) ||
        getVariantColor(variant) === currentColor ||
        getVariantColor(variant) === UNIVERSAL_COLOR_OPTION_VALUE) &&
        !variant.hiddenOnMarket.includes(market)) ??
      false
    )
  })
}
