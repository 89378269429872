"use client"

import { useRouter } from "next/navigation"
import clsx from "clsx"

import { type UnlikelyCustomAttribute, type UnlikelyCustomAttributeInput } from "@unlikelystudio/commerce-connector"
import { cartDictionary, LineItem, LineItems, TemplatesProvider } from "@unlikelystudio/react-ecommerce-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import {
  getProductCustomAttributesValue,
  PRODUCT_CUSTOM_ATTRIBUTES,
  type BundleProductItemCustomAttribute,
  type ColorCustomAttribute,
  type MasterBundleProductCustomAttribute,
} from "~/lib/shopify/constants"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { useCartActions } from "~/hooks/cart/useCartActions"
import useGetCart from "~/hooks/cart/useGetCart"
import { Image, type ImageProps } from "~/components/ui/Image"
import { shopifyLinkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { OPTION_COLOR, UNIVERSAL_COLOR_OPTION_VALUE } from "~/components/ui/Product/ProductHeader/_data/constants"
import Icon from "~/components/abstracts/Icon"
import BottomFullCart from "~/components/globals/Cart/components/BottomFullCart"
import BundleProduct from "~/components/globals/Cart/components/CustomAttributeTemplate/BundleProduct"
import Embroidery from "~/components/globals/Cart/components/CustomAttributeTemplate/Embroidery"
import EmbroideryColor from "~/components/globals/Cart/components/CustomAttributeTemplate/EmbroideryColor"
import FreeDeliveryGauge from "~/components/globals/Cart/components/FreeDeliveryGauge"
import ColorOption from "~/components/globals/Cart/components/OptionTemplate/ColorOption"
import DefaultOption from "~/components/globals/Cart/components/OptionTemplate/DefaultOption"
import { serializeCartPayload } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import type { TCart } from "~/managers/CartManager/_data/serializer"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type FullCartProps = PropsWithClassName<{
  toOffer: NonNullable<TCart>["toOffer"]
  freeDeliveryGauge: NonNullable<TCart>["freeDeliveryGauge"]
  productSlider: NonNullable<TCart>["productSlider"]
  giftPanel: NonNullable<TCart>["giftPanel"]
}>

function FullCart({ className, toOffer, freeDeliveryGauge, productSlider, giftPanel }: FullCartProps) {
  const { cart } = useGetCart()
  const { sendEvent, sendResetEvent } = useTracking()
  const locale = useLocale()
  const t = useTranslate()

  const router = useRouter()

  const { onActionSuccess } = useCartActions()

  return (
    <div className={clsx(className, css.FullCart)}>
      <TemplatesProvider
        dictionary={cartDictionary}
        components={{
          lineItem: (props) => {
            const isEmbroidery = getProductCustomAttributesValue(
              props.lineItem.customAttributes,
              "IS_EMBROIDERY_PRODUCT"
            )

            if (isEmbroidery) return <></>

            //get the master bundle product
            const masterBundleDataFromCustomAttributes = getProductCustomAttributesValue(
              props.lineItem.customAttributes,
              "MASTER_BUNDLE_PRODUCT"
            )

            const masterBundleProduct = masterBundleDataFromCustomAttributes
              ? (JSON.parse(masterBundleDataFromCustomAttributes) as MasterBundleProductCustomAttribute)
              : null

            const uniqLineItemEmbroideryAttributes = getProductCustomAttributesValue(
              props.lineItem.customAttributes,
              "UNIQ_EMBROIDERY_ATTRIBUTES"
            )

            const embroideryLineItem = cart?.lines.find((lineItem) => {
              const uniqEmbroideryAttributes = getProductCustomAttributesValue(
                lineItem.attributes,
                "UNIQ_EMBROIDERY_ATTRIBUTES"
              )

              return (
                getProductCustomAttributesValue(lineItem.attributes, "IS_EMBROIDERY_PRODUCT") &&
                uniqEmbroideryAttributes === uniqLineItemEmbroideryAttributes
              )
            })

            const lineItemAmout = embroideryLineItem
              ? props.lineItem?.variant?.price?.amount + embroideryLineItem?.merchandise?.price?.amount
              : props.lineItem?.variant?.price?.amount

            const selectedOptionsWithoutUniversalColor = props.lineItem.variant?.selectedOptions?.filter(
              (selectedOption: { name: string; value: string }) =>
                selectedOption?.name !== OPTION_COLOR && selectedOption?.value !== UNIVERSAL_COLOR_OPTION_VALUE
            )

            return (
              <LineItem
                {...props}
                onUpdateCallbacks={{
                  onSuccess(data) {
                    onActionSuccess(data)
                  },
                }}
                onRemoveCallbacks={{
                  onSuccess(data, variables, context) {
                    onActionSuccess(data)

                    const deletedLineItemId = variables[0]
                    const deletedLineItem = context.previousCart?.cart?.lines?.find(
                      (lineItem) => lineItem.id === deletedLineItemId
                    )

                    if (deletedLineItem) {
                      sendResetEvent()
                      sendEvent("remove_from_cart", {
                        ecommerce: serializeCartPayload({ ...data.cart, lines: [deletedLineItem] }),
                      })
                    }
                  },
                }}
                onClick={(e, lineItem) => {
                  e.preventDefault()

                  const masterBundleLink = handleZodSafeParse(
                    shopifyLinkSchema,
                    masterBundleProduct ?? lineItem?.variant?.product
                  )

                  const foundLink = masterBundleLink ? serializeLink(masterBundleLink, locale, false) : false

                  if (foundLink) {
                    router.push(foundLink.href)
                  }
                }}
                multiplyPriceByQuantity
                additionalLineItemsToRemove={embroideryLineItem?.id ? [embroideryLineItem.id] : undefined}
                additionalLineItemsToUpdateQuantity={
                  embroideryLineItem?.id
                    ? [
                        {
                          id: embroideryLineItem.id,
                          attributes: embroideryLineItem?.attributes?.filter(isValidAttribute),
                        },
                      ]
                    : undefined
                }
                beforeOptionsChildren={ColorOptionComponent(props.lineItem.customAttributes)}
                lineItem={{
                  ...props.lineItem,
                  variant: {
                    ...props.lineItem.variant,
                    image: masterBundleProduct ? masterBundleProduct.image : props.lineItem.variant.image,
                    selectedOptions: masterBundleProduct ? null : selectedOptionsWithoutUniversalColor,
                    price: {
                      ...(props.lineItem?.variant?.price ?? {}),
                      amount: lineItemAmout,
                    },
                    product: {
                      ...props.lineItem.variant.product,
                      title: masterBundleProduct ? masterBundleProduct.title : props.lineItem.variant.product.title,
                    },
                  },
                }}
                optionTemplate={(option) => {
                  const masterBundleProduct = getProductCustomAttributesValue(
                    props.lineItem.customAttributes,
                    "MASTER_BUNDLE_PRODUCT"
                  )
                  return masterBundleProduct ? null : <DefaultOption className={css.optionLine} option={option} />
                }}
                customAttributeTemplate={(customAttribute) => {
                  switch (customAttribute.key) {
                    case PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT1:
                      return <Embroidery value={customAttribute.value} count={1} />

                    case PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT2:
                      return <Embroidery value={customAttribute.value} count={2} />

                    case PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR1:
                      return <EmbroideryColor color={customAttribute.value} className={sprinkles({ marginTop: 4 })} />

                    case PRODUCT_CUSTOM_ATTRIBUTES.BUNDLE_PRODUCT:
                      const bundeProductData = JSON.parse(customAttribute.value) as BundleProductItemCustomAttribute[]
                      return bundeProductData.map((item, i) => (
                        <BundleProduct key={`product-bundle-item-${i}`} item={item} />
                      ))
                  }
                }}
              />
            )
          },
          img: (props) => {
            const image = props as ImageProps
            return (
              <Image
                {...image}
                height={100}
                width={85}
                asPlaceholder
                sizesFromBreakpoints={[{ ratio: "85px" }]}
                ratio={{ mobile: "50/60", tablet: "85/100" }}
                src={image.src as string}
                alt={image.alt}
              />
            )
          },
          select: (props) => {
            return (
              <div className={css.selectQuantityWrapper}>
                <select {...props} />
                <Icon name="ChevronDown" className={css.select} width={14} height={14} theme="blue-deep" />
              </div>
            )
          },
          button: (props) => {
            return (
              <button {...props} aria-label={t("aria_cart_remove_item")} className={css.buttonRemove}>
                <Icon name="Trash" theme="blue-deep" width={10} height={10} />
              </button>
            )
          },
        }}
      >
        <FreeDeliveryGauge freeDeliveryGauge={freeDeliveryGauge} />
        <LineItems
          sortLineItemsCallback={(a, b) => {
            const isFromCartCrossSellA = getProductCustomAttributesValue(a.attributes, "IS_FROM_CART_CROSS_SELL")
            const isFromCartCrossSellB = getProductCustomAttributesValue(b.attributes, "IS_FROM_CART_CROSS_SELL")

            if (isFromCartCrossSellA && !isFromCartCrossSellB) return 1
            if (!isFromCartCrossSellA && isFromCartCrossSellB) return -1

            return 0
          }}
          classNames={{
            className: css.lineItemsList,
          }}
          lineItemProps={{
            customAttributesWhiteList: [
              PRODUCT_CUSTOM_ATTRIBUTES.MASTER_BUNDLE_PRODUCT,
              PRODUCT_CUSTOM_ATTRIBUTES.BUNDLE_PRODUCT,
              PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_COLOR,
              PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR1,
              PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_FONT,
              PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT1,
              PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT2,
              PRODUCT_CUSTOM_ATTRIBUTES.UNIQ_EMBROIDERY_ATTRIBUTES,
              PRODUCT_CUSTOM_ATTRIBUTES.IS_EMBROIDERY_PRODUCT,
              PRODUCT_CUSTOM_ATTRIBUTES.IS_FROM_CART_CROSS_SELL,
              PRODUCT_CUSTOM_ATTRIBUTES.IS_FROM_POP_IN_CROSS_SELL,
            ],
            editableOptions: false,
            classNames: {
              className: css.lineItem,
              titleClassName: css.title,
              imageClassName: css.image,
              selectQuantityLabelClassName: sprinkles({ display: "none" }),
              lineItemContentClassName: css.lineItemsContent,
              updateQuantityContainerClassName: css.updateQuantityContainer,
              selectQuantityClassName: css.selectQuantity,
              priceClassName: css.price,
              quantityClassName: css.customAttribute,
              optionClassName: css.option,
            },
          }}
        />
      </TemplatesProvider>

      <BottomFullCart toOffer={toOffer} productSlider={productSlider} giftPanel={giftPanel} />
    </div>
  )
}

function ColorOptionComponent(attrs: { key: string; value: string }[]) {
  const productColor = attrs.find((attr) => attr.key === PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_COLOR)
  const data = attrs && productColor && (JSON.parse(productColor.value) as ColorCustomAttribute)

  // Universal color for all locales
  if (data?.value === UNIVERSAL_COLOR_OPTION_VALUE) return null
  return data && <ColorOption option={data} />
}

function isValidAttribute(attribute: UnlikelyCustomAttribute): attribute is UnlikelyCustomAttributeInput {
  return Boolean(attribute.key && attribute.value)
}

export default FullCart
