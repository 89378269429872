import { useEffect, useRef } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"

import type { PropsWithClassName } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAddToCart } from "~/lib/shopify/hooks/useAddToCart"
import serializePrice from "~/data/price/serializer"
import { Form, FormControl, FormError, FormField, FormItem } from "~/components/ui/Form"
import { Image } from "~/components/ui/Image"
import Input from "~/components/ui/Input"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import Textarea from "~/components/ui/Textarea"
import Icon from "~/components/abstracts/Icon"
import { Stack } from "~/components/abstracts/Stack"
import { PANEL_SIZE } from "~/components/globals/Cart/components/GiftPanel/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import type { TCart } from "~/managers/CartManager/_data/serializer"
import { Panel, usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type GiftPanelProps = PropsWithClassName<{
  giftPanel: NonNullable<TCart>["giftPanel"]
}>

function GiftPanel({ className, giftPanel }: GiftPanelProps) {
  const t = useTranslate()
  const locale = useLocale()

  const { mutateAsync: addCartLinesAsync, isLoading } = useAddToCart()

  const { removeCurrent } = usePanel()
  const giftPanelRef = useRef(null)

  function closePanel() {
    removeCurrent()
  }

  const schema = z
    .object({
      products: z.array(
        z.object({
          variantId: z.string(),
          quantity: z.coerce.number(),
          triggerMessageForm: z.coerce.boolean(),
        })
      ),
      giftMessageForm: z
        .object({
          giftMessageTo: z.string().nullish(),
          giftMessageFrom: z.string().nullish(),
          giftMessage: z
            .string({ required_error: t("error_required"), invalid_type_error: t("error_required") })
            .nonempty(t("error_required"))
            .max(
              giftPanel.giftPanelPersonalizedTextMaxChar,
              t("error_max", { max: giftPanel.giftPanelPersonalizedTextMaxChar })
            ),
        })
        .nullish(),
      // Only used for embroideries validation
      productsWithoutQuantities: z.any(),
    })
    .refine(({ products }) => products.some((product) => product.quantity > 0), {
      message: t("error_at_least_one_product"),
      path: ["productsWithoutQuantities"],
    })

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      giftMessageForm: null,
      products: giftPanel.giftPanelItems.flatMap((item) =>
        item.variants[0]?.id
          ? {
              variantId: item.variants[0]?.id,
              quantity: item.triggerMessageForm ? 0 : 1,
              triggerMessageForm: item.triggerMessageForm,
            }
          : []
      ),
      productsWithoutQuantities: null,
    },
    mode: "onChange",
  })

  const { control, watch, setValue, setError } = form

  const withProductTriggerMessageForm = getWithProductTriggerMessageForm(watch("products"))

  useEffect(() => {
    if (!withProductTriggerMessageForm && watch("giftMessageForm")) {
      setValue("giftMessageForm", null)
    }
  }, [withProductTriggerMessageForm, watch("giftMessageForm")])

  const { fields } = useFieldArray({
    control,
    name: "products",
  })

  const submitHandler = form.handleSubmit(async (data) => {
    const itemsWithQuantity = data.products.filter((product) => product.quantity !== 0)

    if (itemsWithQuantity.length === 0) {
      setError("productsWithoutQuantities", { message: t("error_at_least_one_product") })
      return
    }
    const items = itemsWithQuantity.map((product) => ({
      quantity: product.quantity,
      merchandiseId: product.variantId,

      attributes: [
        { key: PRODUCT_CUSTOM_ATTRIBUTES.GIFT, value: "true" },
        ...(product.triggerMessageForm && data.giftMessageForm
          ? [
              { key: PRODUCT_CUSTOM_ATTRIBUTES.GIFT_MESSAGE, value: JSON.stringify(data.giftMessageForm.giftMessage) },
              ...(data.giftMessageForm.giftMessageTo
                ? [{ key: PRODUCT_CUSTOM_ATTRIBUTES.GIFT_MESSAGE_TO, value: data.giftMessageForm.giftMessageTo }]
                : []),
              ...(data.giftMessageForm.giftMessageFrom
                ? [{ key: PRODUCT_CUSTOM_ATTRIBUTES.GIFT_MESSAGE_FROM, value: data.giftMessageForm.giftMessageFrom }]
                : []),
            ]
          : []),
      ],
    }))
    await addCartLinesAsync(items)

    closePanel()
  })

  return (
    <Panel className={clsx(className)} clickOutsideRef={giftPanelRef}>
      <div className={css.GiftPanel} ref={giftPanelRef}>
        <Stack direction="row" gap={10}>
          <button className={css.removeButton} aria-label={t("aria_panel_gift_back")} onClick={closePanel}>
            <Icon name="ArrowLeft" theme="blue-deep" width={14} height={14} />
          </button>
          <RichText
            render={giftPanel.giftPanelTitle}
            className={text({
              design: "spezia-14-18",
              color: "blue-deep",
              transform: "uppercase",
            })}
          />
        </Stack>
        <Image
          className={css.image}
          {...giftPanel.giftPanelImage}
          asPlaceholder
          ratio={{ mobile: "355/282", desktop: "540/282" }}
          sizesFromBreakpoints={[{ ratio: 1 }, { breakpoint: "md", ratio: `${PANEL_SIZE}px` }]}
        />
        <Stack direction="column" gap={15} className={css.contentEdito}>
          <RichText
            className={text({
              design: "riposte-16",
              color: "blue-med",
            })}
            render={giftPanel.giftPanelText}
          />
          <RichText
            className={text({
              design: "riposte-13",
              color: "error",
            })}
            render={giftPanel.giftPanelNotaBene}
          />
        </Stack>

        <Form {...form}>
          <form onSubmit={submitHandler}>
            <Stack gap={22} direction="column" responsiveGap={{ tablet: 15 }}>
              {fields.length > 0 && (
                <Stack direction="column" gap={10} sprinklesCss={{ marginTop: 25 }}>
                  {fields.map((field, index) => {
                    const item = giftPanel.giftPanelItems.find((item) => item.variants[0]?.id === field.variantId)
                    if (!item) {
                      return null
                    }
                    const currentQuantity = Number(watch(`products.${index}.quantity`))

                    const price = serializePrice({
                      locale,
                      currencyCode: item.variants[0]?.unserializedPrice?.currencyCode,
                      amount: (item.variants[0]?.unserializedPrice?.amount ?? 0) * (currentQuantity || 1),
                    })

                    return (
                      <div className={css.item} key={`gift-panel-item-${index}`}>
                        {item.images[0] && (
                          <Image
                            asPlaceholder
                            className={css.itemImage}
                            {...item.images[0]}
                            height={100}
                            width={85}
                            sizesFromBreakpoints={[{ ratio: "85px" }]}
                            ratio={{ mobile: "50/60", tablet: "85/100" }}
                          />
                        )}

                        <div className={css.itemContent}>
                          <Stack direction="column" gap={12} sprinklesCss={{ paddingRight: 5 }}>
                            <h3
                              className={text({
                                design: "riposte-14",
                                color: "blue-med",
                              })}
                            >
                              {item.title}
                            </h3>
                            <p
                              className={text({
                                design: "riposte-13",
                                color: "blue-light-txt",
                              })}
                            >
                              {item.subtitle}
                            </p>
                          </Stack>

                          <div className={css.selectAndPrice}>
                            <FormField
                              control={control}
                              name={`products.${index}.quantity`}
                              render={({ field }) => (
                                <FormItem className={css.selectQuantityWrapper}>
                                  <FormControl>
                                    <select
                                      className={css.selectQuantity}
                                      value={field.value}
                                      onChange={field.onChange}
                                    >
                                      {Array.from({ length: item.maxQuantity + 1 }).map((_, i) => (
                                        <option key={i} value={i}>
                                          {i}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                  <Icon
                                    name="ChevronDown"
                                    className={css.select}
                                    width={14}
                                    height={14}
                                    theme="blue-deep"
                                  />
                                </FormItem>
                              )}
                            />

                            <div className={css.price}>{price}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Stack>
              )}
              <FormField
                control={form.control}
                name="productsWithoutQuantities"
                render={() => (
                  <FormItem>
                    <FormError />
                  </FormItem>
                )}
              />
              {withProductTriggerMessageForm && (
                <div>
                  <RichText
                    className={text({
                      design: "spezia-18-24",
                      color: "blue-deep",
                      transform: "uppercase",
                    })}
                    render={giftPanel.giftPanelPersonalizedWordTitle}
                  />
                  <Stack sprinklesCss={{ marginTop: 15 }} direction="column" gap={8}>
                    <Stack direction="row" gap={8} sprinklesCss={{ alignItems: "center", justifyContent: "center" }}>
                      <FormField
                        control={control}
                        name="giftMessageForm.giftMessageTo"
                        render={({ field }) => (
                          <FormItem className={sprinkles({ width: "100%" })}>
                            <FormControl>
                              <Input
                                {...field}
                                value={field.value ?? ""}
                                placeholder={t("form_message_to")}
                                errorBackgroundColor="white"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={control}
                        name="giftMessageForm.giftMessageFrom"
                        render={({ field }) => (
                          <FormItem className={sprinkles({ width: "100%" })}>
                            <FormControl>
                              <Input
                                {...field}
                                value={field.value ?? ""}
                                placeholder={t("form_message_from")}
                                errorBackgroundColor="white"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </Stack>
                    <FormField
                      control={control}
                      name="giftMessageForm.giftMessage"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              {...field}
                              rows={4}
                              value={field.value ?? ""}
                              placeholder={t("form_message") + "*"}
                              maxLength={giftPanel.giftPanelPersonalizedTextMaxChar}
                              errorBackgroundColor="white"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <div
                      className={clsx(
                        text({
                          design: "riposte-10",
                          color: "text-grey",
                          transform: "uppercase",
                        }),
                        sprinkles({ mT: 2 })
                      )}
                    >
                      {t("cart_gift_panel_maximum_characters", { number: giftPanel.giftPanelPersonalizedTextMaxChar })}
                    </div>
                  </Stack>
                </div>
              )}
            </Stack>
            <RoundedCta
              size="large"
              isLoading={isLoading}
              className={css.addToCart}
              htmlType="submit"
              width="full"
              theme="blue-deep"
            >
              {giftPanel.giftPanelAddToCartLabel}
            </RoundedCta>
          </form>
        </Form>
      </div>
    </Panel>
  )
}

function getWithProductTriggerMessageForm(
  fields: {
    triggerMessageForm: boolean
    quantity: number
  }[]
) {
  return fields.some((field) => field.triggerMessageForm && field.quantity > 0)
}

export default GiftPanel
