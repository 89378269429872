"use client"

import type { ElementType } from "react"
import type { InterpolationOptions } from "node-polyglot"

import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

type ClientTranslateProps = {
  tKey: keyof Dictionary
  options?: number | InterpolationOptions
  as?: ElementType
  className?: string
  children?: React.ReactNode
}

export function ClientTranslate({ as = "div", tKey, options, children, ...props }: ClientTranslateProps) {
  const t = useTranslate()

  const Tag = as

  return (
    <Tag {...props}>
      {t(tKey, options)}
      {children}
    </Tag>
  )
}
