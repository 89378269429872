"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

type PanelTopProps = PropsWithClassName<{ title: string; onClickClose?: () => void; largeFont?: boolean }>
function PanelTop({ className, title, onClickClose, largeFont = false }: PanelTopProps) {
  const t = useTranslate()
  const { removeCurrent } = usePanel()

  const removePanel = () => {
    removeCurrent()
  }

  return (
    <div className={clsx(className, css.PanelTop, css.isSticky)}>
      <div
        className={text({
          design: largeFont ? "spezia-15-19" : "spezia-14-18",
          color: "blue-deep",
          weight: "semiBold",
          transform: "uppercase",
          letterSpacing: 0.4,
        })}
      >
        {title}
      </div>
      <button
        className={css.button}
        aria-label={t("aria_panel_close")}
        onClick={() => {
          removePanel()
          onClickClose?.()
        }}
      >
        <Icon name="Cross" theme="blue-deep" width={14} height={14} />
      </button>
    </div>
  )
}

export { PanelTop, type PanelTopProps }
