import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import RichText from "~/components/ui/RichText"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"

import { text } from "~/styles/variants"

export type EmptyCartProps = PropsWithClassName<{
  empty: {
    emptyCartTitle: Nullish<SbRichText>
  }
}>

function EmptyCart({ className, empty }: EmptyCartProps) {
  return (
    <div className={clsx(className)}>
      <RichText
        className={text({
          design: "spezia-80-110",
          color: "blue-deep",
          transform: "uppercase",
          letterSpacing: 0.3,
        })}
        render={empty.emptyCartTitle}
      />
    </div>
  )
}

export default EmptyCart
