import type { PropsWithClassName } from "~/@types/generics"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { text } from "~/styles/variants"

export type DefaultOption = PropsWithClassName<{
  option: {
    name: string
    value: string
  }
}>

function DefaultOption({ className, option }: DefaultOption) {
  const t = useTranslate()

  //ignore the color option by the name
  //the name can be translated so we need to match it because we get the value by shopify
  //so we used a dictionnary key - we can't have an id or something immutable
  if (!option || !option.name || !option.value || option.name === t("cart_ignore_color_option")) return null

  return (
    <div className={className}>
      <span
        className={text({
          design: "riposte-13",
          color: "blue-light-txt",
        })}
      >
        {option.name} :{" "}
      </span>
      <span
        className={text({
          design: "riposte-13",
          color: "blue-deep",
        })}
      >
        {option.value}
      </span>
    </div>
  )
}

export default DefaultOption
