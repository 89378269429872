import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { BundleProductItemCustomAttribute } from "~/lib/shopify/constants"
import { Stack } from "~/components/abstracts/Stack"
import ColorOption from "~/components/globals/Cart/components/OptionTemplate/ColorOption"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type BundleProductProps = PropsWithClassName<{
  item: BundleProductItemCustomAttribute
}>

function BundleProduct({ className, item }: BundleProductProps) {
  return (item.size.title && item.size.value) || item.color.value ? (
    <li className={clsx(className, css.BundleProduct)}>
      <Stack direction={"column"} gap={2}>
        {item.size.title && item.size.value && (
          <div>
            <span
              className={text({
                design: "riposte-11",
                color: "blue-light-txt",
              })}
            >
              {item.size.title} :
            </span>
            <span
              className={text({
                design: "riposte-11",
                color: "blue-deep",
              })}
            >
              {" " + item.size.value}
            </span>
          </div>
        )}

        <ColorOption theme="small" option={item.color} />
      </Stack>
    </li>
  ) : null
}

export default BundleProduct
