import { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { getSfMedias } from "~/lib/shopify/queries/get-sf-medias"
import { parseShopifyReferencesFromMetatield } from "~/lib/shopify/utils/parse-references-from-metafields"
import { enumWithDefaultSchema } from "~/lib/zod/utils/enum-with-default"
import { gidSchema } from "~/lib/zod/utils/gid-schema"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"

export async function serializeEmbroideryImages(
  productEmbroideryImages: Nullish<string>,
  metaobject: TEmbroideryMetaobject
) {
  const parsedImages = parseShopifyReferencesFromMetatield(productEmbroideryImages) ?? metaobject.images

  const { medias } = await getSfMedias({ withLocale: false, ids: parsedImages ?? [] })
  return medias ? serializeShopifyImages(medias) : []
}

export const embroideryMetaobjectSchema = z.object({
  type: enumWithDefaultSchema(["small", "long", "double"], "small"),
  title: z.string().nullish(),
  description: z.string().nullish(),
  footer_description: z.string(),
  images: z.string().nullish().transform(parseShopifyReferencesFromMetatield),
  length: z.coerce.number(),
  one_embroidery_product: gidSchema,
  two_embroidery_product: gidSchema,
})

export type TEmbroideryMetaobject = z.infer<typeof embroideryMetaobjectSchema>
