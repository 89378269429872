import type { GID, UnlikelyMetafields } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getProductMetafieldValue, getVariantMetafieldProccessedKeys } from "~/lib/shopify/constants"
import { COLORS_SELECTOR_TYPES } from "~/lib/shopify/constants/types"
import { embroideryMetaobjectSchema, serializeEmbroideryImages } from "~/lib/shopify/metaobjects/embroidery"
import { serializeMetaobject } from "~/lib/shopify/metaobjects/serializer"
import { getSfMetaobject } from "~/lib/shopify/queries/get-sf-metaobject"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { serializeVariants } from "~/lib/shopify/serializers/serialize-variants"
import { metafieldRichTextToHTMLString } from "~/lib/shopify/utils/rich-text-metafield-to-html"
import serializePrice from "~/data/price/serializer"
import { getUniqColorsWithDotImage } from "~/components/ui/Product/ProductHeader/_data/utils/get-uniq-colors-with-dot-image"

export type TEmbroidery = InferReturn<typeof serializeEmbroidery>

export async function serializeEmbroidery(
  product: { metafields: Nullish<UnlikelyMetafields> },
  locale: Nullish<string>
) {
  const embroideryMetaobjectId = getProductMetafieldValue(product?.metafields, "EMBROIDERY") as Nullish<GID>

  if (!embroideryMetaobjectId) {
    return null
  }

  const { metaobject } = await getSfMetaobject({ id: embroideryMetaobjectId, locale })
  const embroideryMetaobject = serializeMetaobject(embroideryMetaobjectSchema, metaobject)

  if (!embroideryMetaobject) {
    return null
  }

  const [oneEmbroideryProduct, twoEmbroideryProduct] = await Promise.all([
    fetchEmbroideryProduct(embroideryMetaobject.one_embroidery_product, locale),
    fetchEmbroideryProduct(embroideryMetaobject.two_embroidery_product, locale),
  ])

  const title = getProductMetafieldValue(product?.metafields, "EMBROIDERY_TITLE") ?? embroideryMetaobject.title ?? null
  const description =
    getProductMetafieldValue(product?.metafields, "EMBROIDERY_DESCRIPTION") ?? embroideryMetaobject.description ?? null

  return {
    description: metafieldRichTextToHTMLString(description),
    footerDescription: metafieldRichTextToHTMLString(embroideryMetaobject.footer_description),
    title,
    type: embroideryMetaobject.type,
    images: await serializeEmbroideryImages(
      getProductMetafieldValue(product?.metafields, "EMBROIDERY_IMAGES"),
      embroideryMetaobject
    ),
    oneEmbroideryProduct,
    twoEmbroideryProduct,
    length: embroideryMetaobject.length,
  } as const
}

async function fetchEmbroideryProduct(id: GID, locale: Nullish<string>) {
  const { product } = await getSfProduct({
    locale,
    id,
    variantsFirst: 20,
    includeSelectedOptions: true,
    includePrices: true,
    metafieldVariantsKeys: getVariantMetafieldProccessedKeys("COLOR_DOT_IMAGE"),
  })

  const variants = await serializeVariants(product?.variants, locale)
  const colorsWithDotImage = getUniqColorsWithDotImage(variants, COLORS_SELECTOR_TYPES.EMBROIDERY)

  return product
    ? {
        ...product,
        price: serializePrice({
          locale,
          amount: product?.priceRange?.minVariantPrice?.amount,
          currencyCode: product?.priceRange?.minVariantPrice?.currencyCode,
        }),
        unserializedPrice: product?.priceRange?.minVariantPrice,
        colorsWithDotImage,
        variants,
      }
    : null
}
